import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledLicenseBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [40]
  })
)

export const styledLicense = css`
  ${styledLicenseBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 600, 600],
    margin: ['0 auto', '0 auto'],
    marginLeft: [0],
    marginRight: [0],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [300, '100%', 540, 540],
    margin: ['0 auto', '0 auto'],
    marginLeft: [0],
    marginRight: [0],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const licensesBps = css(
  mq({
    paddingTop: [50],
    paddingBottom: [60]
  })
)

export const licenseItemBps = css(
  mq({
    marginBottom: [16, 16, 24, 24]
  })
)

export const licenseItems = css(
  mq({
    display: ['flex'],
    margin: ['0 auto'],
    justifyContent: ['center']
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
  }
`

export const licenses = css`
  ${licensesBps};
  background-color: #f3f5f5;
  h5 {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
  }
`

export const licenseItem = css`
  ${licenseItemBps};
  width: 328px;
  height: 377px;
  border-radius: 18px;
  background-color: #ffffff;
  p {
    width: 296px;
    height: 144px;
    padding: 15.5px 16px 24px 16px;
    text-align: center;
    align-items: center;
    display: flex;
  }
`

export const licenseHeader = css`
  display: flex;
  align-items: center;
  padding: 16px 0;
  /* border-bottom: 1px solid #f3f5f5; */
  img {
    width: 126px;
    height: 66px;
    object-fit: contain;
  }
`

export const circleItem = css`
  width: 70px;
  height: 70px;
  background-color: #fff5ef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const licenseAction = css`
  display: flex;
  flex-direction: row;
  padding: 0 30px;
  div:nth-child(2) {
    margin-left: 28px;
    margin-right: 28px;
  }
`
export const currency = css`
  font-size: 13.8px;
  font-weight: 600;
  line-height: 1.74;
  letter-spacing: 0.1px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
`
export const radioBtn = css`
  position: relative;
  cursor: pointer;
  line-height: 20px;
  font-size: 24px;
  input[type="radio"]:checked + .divBtn {
    border-color: #909;
    &:after { 
      transform: scale(1);
      transition: all .2s cubic-bezier(.35,.9,.4,.9);
      opacity: 1;
    }
  }
`

export const hiddenBtn = css`
  display: none;
`

export const divBtn = css`
  position: relative;
  display: block;
  float: left;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid red;
  border-radius: 100%;
  -webkit-tap-highlight-color: transparent;
  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: $primary;
    transform: scale(0);
    transition: all .2s ease;
    opacity: .08;
    pointer-events: none;
  }
  &:hover {
    .label:after {
      transform: scale(3.6);
    }
  }
`
export const scroll = css`
  overflow: hidden !important;
`

export const licenceContent = css`
  margin-top: 16px;
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  div:not(:last-child) {
    margin-bottom: 20px;
  }
`
export const collapseItem = css`
  div {
    overflow: hidden !important;
  }
`

export const valueContent = css`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212121;
  align-items: center;
  display: flex;
`
export const textContent = css`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 8px;
  align-items: center;
  display: flex;
`
export const textContentCp = css`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #737474;
`
export const textContentCp2 = css`
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 8px !important;
  align-items: center;
  display: flex;
  margin-top: -18px !important;
  color: #ff5800 !important;
`