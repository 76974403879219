import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import CPanel from '../images/licenses/cpanel.png'
import CloudLinux from '../images/licenses/cloudlinux.png'
import FantasticoDeluxe from '../images/licenses/fantastico-deluxe.png'
import Imunufy from '../images/licenses/imunufy.png'
import kernelCare from '../images/licenses/kernel-care.png'
import Rvskin from '../images/licenses/rvskin.png'
import Softaculous from '../images/licenses/softaculous.png'
import WhmExtra from '../images/licenses/whm-extra.png'
import WHMSonic from '../images/licenses/whm-sonic.png'

import { Container } from '../components/styles/Layout.styles'
import LicenseItem from '../components/LicencesItem'
import LicenceCpanel from '../components/LicenceCPanel'
import LicencesItemNotConfig from '../components/LicencesItemNotConfig'
import LicenceSoftaculous from '../components/LicenceSoftaculous'
import { 
  styledLicense,
  header,
  licenses
} from '../components/styles/License.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledLicense}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>
                <span>licenças</span><br />
                Ofertas exclusivas para clientes da HostDime Brasil
              </H4>
              <Paragraph>Através de nossas fortes parcerias com nossos fornecedores de softwares, somos capazes de oferecer licenças com descontos em produtos de terceiros. Estas ofertas são exclusivas para clientes da HostDime Brasil.</Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
      <section css={licenses}>
        <Container>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
          >
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceCpanel
                  licenceImg={CPanel}
                  licenceDescription={`Através de nossa parceria com o cPanel, oferecemos o software líder de controle e administração de hospedagens.`}
                  licencePrice={'219,00'}
                  configurationItem1={`100 Contas`}
                  configurationItem2={`R$ 219,00/mês`}
                  configurationItem3={`Conta adicional:`}
                  configurationItem4={`R$ 0,99/mês`}
                  notPrice={`. . . . . . . .\n. . . . . . . .`}
                  notPrice2={`. . . . . . . .\n. . . . . . . .`}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/a80198fb6c991ac5715f'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicencesItemNotConfig
                  licenceImg={FantasticoDeluxe}
                  licenceDescription={`O Fantastico é o mais conhecido e o mais estável software de instalação de aplicações, além de ter sido o primeiro a surgir do seu grupo. Usado para instalar milhões de instâncias dos principais softwares por milhões de usuários.`}
                  licencePrice={'25,00'}
                  notPrice={`. . . . . . . .\n. . . . . . . .`}
                  notPrice2={`. . . . . . . .\n. . . . . . . .`}
                  notPrice3={`. . . . . . . .\n. . . . . . . .`}
                  notPrice4={`. . . . . . . .\n. . . . . . . .`}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/2e390a1996001c66d4a2'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicencesItemNotConfig
                  licenceImg={WHMSonic}
                  licenceDescription={`WHMSonic é um plugin desenvolvido para o cPanel que permite a você e a seus clientes gerenciarem serviços de streaming diretamente do cPanel.`}
                  licencePrice={'25,00'}
                  notPrice={`. . . . . . . .\n. . . . . . . .`}
                  notPrice2={`. . . . . . . .\n. . . . . . . .`}
                  notPrice3={`. . . . . . . .\n. . . . . . . .`}
                  notPrice4={`. . . . . . . .\n. . . . . . . .`}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/a820766feaff205ae55b'}
                />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
          >
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicencesItemNotConfig
                  licenceImg={WhmExtra}
                  licenceDescription={`O WHMXtra instala firewalls, proteção DDOS, FFMPEG e muitas mais. Tudo em WHMXtra é projetado para poupar tempo e dinheiro quando se trata em proteger, reparar e gerenciar o seu servidor!`}
                  licencePrice={'25,00'}
                  notPrice={`. . . . . . . .\n. . . . . . . .`}
                  notPrice2={`. . . . . . . .\n. . . . . . . .`}
                  notPrice3={`. . . . . . . .\n. . . . . . . .`}
                  notPrice4={`. . . . . . . .\n. . . . . . . .`}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/6f589d4a59db65dbcedf'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceSoftaculous
                  licenceImg={Softaculous}
                  licenceDescription={`O Softaculous é um novo instalador automático com mais de 140 softwares que está sendo sempre atualizado com novos scripts. Estes scripts suprem a grande maioria das necessidades que seus clientes venham a ter.`}
                  licencePrice='25,00'
                  configurationItem1={`Dedicado`}
                  configurationItem2={`R$ 25,00/mês`}
                  buyLinkDedicado={'https://core.hostdime.com.br/purchaselicense/index/id/47f9bd7f01b0f20e2b22'}
                  configurationItem3={`VPS`}
                  configurationItem4={`R$ 25,00/mês`}
                  buyLinkVPS={'https://core.hostdime.com.br/purchaselicense/index/id/55819e36822f48d0c496'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicencesItemNotConfig
                  licenceImg={Rvskin}
                  licenceDescription={`RVSkin é um gerenciador de temas em várias línguas adaptado especificamente para servidores cPanel. Use RVSkin para divulgar sua empresa e ajudar o seu negócio a se destacar no mercado.`}
                  licencePrice={'25,00'}
                  notPrice={`. . . . . . . .\n. . . . . . . .`}
                  notPrice2={`. . . . . . . .\n. . . . . . . .`}
                  notPrice3={`. . . . . . . .\n. . . . . . . .`}
                  notPrice4={`. . . . . . . .\n. . . . . . . .`}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/95abd1b14c9777066278'}
                />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
          >
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicencesItemNotConfig
                  licenceImg={CloudLinux}
                  licenceDescription={`CloudLinux mantém seus servidores estáveis e seus clientes satisfeitos, mesmo sob extremo estresse. Isto ocorre porque cada conta é isolada em seu ambiente virtualizado (LVE) com recursos pré-configurados por você.`}
                  licencePrice={'60,00'}
                  notPrice={`. . . . . . . .\n. . . . . . . .`}
                  notPrice2={`. . . . . . . .\n. . . . . . . .`}
                  notPrice3={`. . . . . . . .\n. . . . . . . .`}
                  notPrice4={`. . . . . . . .\n. . . . . . . .`}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/e61dc83430f632161a0c'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenseItem
                  licenceImg={Imunufy}
                  licenceDescription={`O Imunify360 é um sistema de segurança muito sofisticado, que coleta e processa constantemente um grande número de informações sobre novos ataques em servidores ao redor do mundo.`}
                  licencePrice='24,99'
                  configurationItem1={`Usuários ilimitados`}
                  configurationItem2={`R$ 99,99/servidor/mês`}
                  configurationItem3={`1 usuário`}
                  configurationItem4={`R$ 24,99/servidor/mês`}
                  configurationItem5={`Até 30 usuários`}
                  configurationItem6={`R$ 49,99/servidor/mês`}
                  configurationItem7={`Até 250 usuários`}
                  configurationItem8={`R$ 79,99/servidor/mês`}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/4338848bfa5325b85105'}
                />
              </Div>
            </Column>
            <Column
              mb={[16, 16, 24, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <LicencesItemNotConfig
                  licenceImg={kernelCare}
                  licenceDescription={`KernelCare é um serviço que fornece atualizações importantes para o seu kernel em execução, eliminando a necessidade de reiniciar o servidor.`}
                  licencePrice={'25,00'}
                  notPrice={`. . . . . . . .\n. . . . . . . .`}
                  notPrice2={`. . . . . . . .\n. . . . . . . .`}
                  notPrice3={`. . . . . . . .\n. . . . . . . .`}
                  notPrice4={`. . . . . . . .\n. . . . . . . .`}
                  buyLink={'https://core.hostdime.com.br/purchaselicense/index/id/0d89769d20769b7d85bc'}
                />
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Licenças | Exclusividade para clientes HostDime' 
        description='Clientes HostDime têm licenças de softwares dos nossos parceiros com desconto exclusivo. Confira as empresas participantes!' 
      />
      <PageTemplate
        title='Licenças'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage