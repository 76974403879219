import React from 'react'
import { css } from '@emotion/core'

import { mq } from '../components/styles/Layout.styles'
import Div from '../components/Div'
import H3 from '../components/H3'
import Body2 from '../components/Body2'
import Paragraph from './Paragraph'
import ds from '../theme'

import {
  licenseHeader,
  currency
} from '../components/styles/License.styles'
import { btnSmall } from '../components/styles/OutlineButton.styles'
 
const LicencesItemNotConfig = ({
  licencePrice,
  licenceImg,
  licenceDescription,
  notPrice,
  notPrice2,
  notPrice3,
  notPrice4,
  buyLink
}) => {
  return (
    <div
      style={{
        backgroundColor: '#ffffff',
        borderRadius: 18,
        paddingBottom: 50
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <Div css={licenseHeader}>
          <img src={licenceImg} />
        </Div>
        <Paragraph
          style={{
            width: 296,
            height: 144,
            padding: '16px',
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          {licenceDescription}
        </Paragraph>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            marginBottom: 20
          }}
        />
        <H3
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <span css={currency}>R$</span>
          {licencePrice}
          <span css={currency}>/mês</span>
        </H3>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '20px 0'
          }}
        />
        <Body2 
          style={{
            fontSize: 14,
            fontWeight: 700,
            marginBottom: 12,
            color: '#d9dee3',
            lineHeight: 1.71,
            whiteSpace: 'pre-wrap',
            marginTop: 20
          }}>
          {notPrice}
        </Body2>
        <Body2 
          style={{
            fontSize: 14,
            fontWeight: 700,
            marginBottom: 12,
            color: '#d9dee3',
            lineHeight: 1.71,
            whiteSpace: 'pre-wrap'
          }}>
          {notPrice2}
        </Body2>
        <Body2 
          style={{
            fontSize: 14,
            fontWeight: 700,
            marginBottom: 12,
            color: '#d9dee3',
            lineHeight: 1.71,
            whiteSpace: 'pre-wrap'
          }}>
          {notPrice3}
        </Body2>
        <Body2 
          style={{
            fontSize: 14,
            fontWeight: 700,
            marginBottom: 50,
            color: '#d9dee3',
            lineHeight: 1.71,
            whiteSpace: 'pre-wrap'
          }}>
          {notPrice4}
        </Body2>
        <a
          href={buyLink}
          css={btnSmall}
        >
          COMPRAR
        </a>
      </Div>
    </div>
  )
}

export default LicencesItemNotConfig
